import * as React from 'react';

import { Link as CLink, Text } from '@chakra-ui/react';

import {
  Link,
  PodcastWindow,
  StudioRoutes,
  useSession,
  UserLayout,
  PageHero,
} from '@theqube/ui';

export default function HomePage() {
  const [{ data: session, isSuccess }] = useSession();

  return (
    <PageHero
      isLoaded={Boolean(isSuccess && session)}
      heading={{ src: '/images/logo--full.png', alt: 'The Qube Logo' }}
      thumbnail={{
        src: '/images/logo.png',
        alt: 'The Qube Logo',
        height: '56',
      }}
      maxW="auto"
    >
      <PodcastWindow
        key="feed"
        query={{
          where: {
            tags: {
              some: {
                OR: session?.user.tags.map(({ name }) => ({ name })),
              },
            },
          },
        }}
        header={
          <Text as="h2" fontSize="3xl" fontWeight="bold">
            For You
          </Text>
        }
      />

      <PodcastWindow
        key="updated"
        query={{ orderBy: { updatedAt: 'desc' } }}
        header={
          <>
            <Text as="h2" fontSize="3xl" fontWeight="bold">
              Recently Updated
            </Text>
          </>
        }
      />

      <PodcastWindow
        key="published"
        query={{ orderBy: { publishedAt: 'desc' } }}
        header={
          <Text as="h2" fontSize="3xl" fontWeight="bold">
            Recently Published
          </Text>
        }
      />

      {session?.user.tags
        .filter((tag) => tag.type === 'category')
        .map(({ id, name }) => (
          <PodcastWindow
            key={id}
            query={{
              orderBy: { publishedAt: 'desc' },
              where: { tags: { some: { id } } },
            }}
            header={
              <>
                <Text as="h2" fontSize="3xl" fontWeight="bold">
                  {name}
                </Text>
                <Link
                  href={
                    StudioRoutes.discover.children?.tags.children?.id.href(
                      String(id)
                    ) ?? ''
                  }
                >
                  See All
                </Link>
              </>
            }
            empty={{
              title:
                "There doesn't seem to be any podcasts within this category",
              description: "We're still building! Have a podcast in mind?",
              action: (
                <CLink
                  href="http://bit.ly/JoinTheQubeForm"
                  target="_blank"
                  rel="noreferrer"
                >
                  Submit a podcast.
                </CLink>
              ),
            }}
          />
        )) ?? null}
    </PageHero>
  );
}

HomePage.getLayout = (page: React.ReactNode) => (
  <UserLayout title="Home" footer={null}>
    {page}
  </UserLayout>
);
